
import RingSVG from '@/components/svg/social/RingSVG.vue'
import HoverRingSVG from '@/components/svg/social/HoverRingSVG.vue'
export default {
  components: {
    RingSVG,
    HoverRingSVG,
  },
  props: {
    link: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentComponent: '',
    }
  },
  computed: {
    currentProperties() {
      if (this.getComponent() === 'linkPart') return { link: this.link }
      return null
    },
  },
  methods: {
    getComponent() {
      if (!this.link || Object.keys(this.link).length === 0) return 'span'
      return 'linkPart'
    },
  },
}
