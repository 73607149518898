
import { mapGetters } from 'vuex'
import ArrowUpSVG from '@/components/svg/icon/ArrowUpSVG.vue'
import bodytext from '@/mixins/bodytext'
import LogoLGSVG from '@/components/svg/LogoLGSVG.vue'
import FooterDetailsSVG from '@/components/svg/footer/FooterDetailsSVG.vue'
import FooterLineSVG from '@/components/svg/footer/FooterLineSVG.vue'
import Socials from '@/components/parts/Socials.vue'
import BtnQuaternary from '@/components/parts/button/BtnQuaternary.vue'
export default {
  components: {
    LogoLGSVG,
    FooterDetailsSVG,
    FooterLineSVG,
    Socials,
    BtnQuaternary,
    ArrowUpSVG,
  },
  mixins: [bodytext],
  data() {
    return {
      tl: null,
    }
  },
  computed: {
    ...mapGetters({
      footer: 'getFooter',
    }),
  },
  // beforeMount() {
  //   this.$nuxt.$on('go', this.anim)
  // },
  mounted() {
    // this.$nuxt.$on('go', this.anim)
    this.tl = this.$gsap.timeline({
      // scrollTrigger: {
      //   trigger: this.$el,
      //   toggleActions: 'play reset play reverse',
      // },
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        // toggleActions: 'play reset play reverse',
        once: true,
      },
    })
    if (this.$refs.body) {
      const body = new this.$SplitText(this.$refs.body, { type: 'chars, words' })
      this.tl.from(body.chars, { opacity: 0.3, color: '#42795C', duration: 0.2, stagger: 0.005 }, '<')
    }

    this.$refs?.socials?.$refs?.social &&
      this.tl.from(
        this.$refs.socials.$refs.social.map((x) => x.$el),
        { opacity: 0, duration: 0.3, stagger: 0.03 },
        '>'
      )
  },
  methods: {
    top() {
      this.$gsap.to(window, { duration: 1, scrollTo: 0, ease: 'custom.1' })
    },
  },
  // beforeDestroy() {
  //   this.$nuxt.$off('go', this.anim)
  // },
  // methods: {
  //   anim() {
  //     this.tl.scrollTrigger.enable()
  //   },
  // },
}
