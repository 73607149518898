
import BtnRing from '@/components/parts/button/BtnRing.vue'
export default {
  components: {
    BtnRing,
  },
  props: {
    socials: {
      type: Array,
      default: () => {},
    },
  },
}
