
export default {
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    el: {
      type: String,
      default: 'button',
    },
  },
  data() {
    return {
      currentComponent: '',
    }
  },
  computed: {},
  methods: {
    getComponent() {
      if (Object.keys(this.link).length === 0) return this.el
      return 'linkPart'
    },
    currentProperties() {
      if (this.getComponent() === 'linkPart') {
        return { link: { url: this.link.url, label: this.link.label } }
      }
      return null
    },
  },
}
